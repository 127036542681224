<template>
    <router-view></router-view>
</template>

<script>

export default {
  name: "layout",
  data() {
    return {
    };
  },
  created() {
  },
  methods: {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.content {
    min-height: 700px;
    .m-card {
    border: solid 1px #ededed;
    .mc-top-title::before {
      opacity: 0;
      font-size: 16px;
    }
  }
}
</style>
